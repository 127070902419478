<template>
  <fw-layout back-to="/manage">
    <template #main-content>
      <fw-heading size="xl">{{ $t('configurations') }}</fw-heading>

      <fw-panel :title="$t('editions')" custom-class="bg-white p-2" boxed class="my-5">
        <template v-if="userPermissions.isGlobalManager" #toolbar>
          <span class="flex flex-1 justify-end">
            <fw-button type="transparent-primary" @click.native="editEdition(null)">{{
              $t('newEdition')
            }}</fw-button></span
          >
        </template>
        <PanelManageEditions ref="panelManageEditions" @edit="editEdition($event)"></PanelManageEditions>
      </fw-panel>

      <fw-panel :title="$t('modalities')" custom-class="bg-white p-2" boxed class="my-5">
        <template v-if="userPermissions.isGlobalManager" #toolbar>
          <span class="flex flex-1 justify-end">
            <fw-button type="transparent-primary" @click.native="editModality(null)">{{
              $t('newModality')
            }}</fw-button></span
          >
        </template>
        <PanelManageModalities ref="panelManageModalities" @edit="editModality($event)"></PanelManageModalities>
      </fw-panel>
    </template>

    <template #modals>
      <fw-modal v-if="isModalEditionActive" :active.sync="isModalEditionActive" :can-cancel="true" @close="closeModal">
        <template #default>
          <ModalCreateEdition :edition="edition" @close="closeModal" @saved="editionSaved()"></ModalCreateEdition>
        </template>
      </fw-modal>
      <fw-modal v-if="isModalSportActive" :active.sync="isModalSportActive" :can-cancel="true" @close="closeModal">
        <template #default>
          <ModalCreateSport :sport="sport" @close="closeModal"></ModalCreateSport>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import PanelManageModalities from '@/components/panels/manage/PanelManageModalities'
import PanelManageEditions from '@/components/panels/manage/PanelManageEditions'
import ModalCreateEdition from '@/components/modals/ModalCreateEdition'
import ModalCreateSport from '@/components/modals/ModalCreateSport'

export default {
  name: 'ManageEditionsAndModalities',
  components: {
    PanelManageModalities,
    PanelManageEditions,
    ModalCreateEdition,
    ModalCreateSport
  },

  data() {
    return {
      isModalActive: false,
      isModalEditionActive: false,
      isModalSportActive: false,
      edition: null, //edition being edited
      sport: null //sport being edited
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    userPermissions() {
      return this.$store.getters.userPermissions
    }
  },

  methods: {
    editEdition(edition) {
      this.edition = edition
      this.isModalEditionActive = true
    },
    editModality(sport) {
      this.sport = sport
      this.isModalSportActive = true
    },
    editionSaved() {
      this.$refs.panelManageEditions.refresh()
      this.closeModal()
    },
    closeModal() {
      this.isModalEditionActive = false
      this.isModalSportActive = false
    }
  }
}
</script>

<i18n>
{
    "pt": {
        "editions": "Edições",
        "newEdition": "Nova edição",
        "modalities": "Modalidades",
        "newModality": "Nova modalidade",
        "configurations": "Configurações"
    },
    "en": {
        "editions": "Editions",
        "newEdition": "New edition",
        "modalities": "Modalities",
        "newModality": "New modality",
        "configurations": "Configurations"
    }
}
</i18n>
