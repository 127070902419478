<template>
  <div>
    <LoadingPlaceholder v-if="loading" class="my-5" />
    <div v-else-if="sports.length > 0">
      <RecordSport
        v-for="sport in sports"
        :key="sport.key"
        :sport="sport"
        :modalities="modalities"
        @click.native="$emit('edit', sport)"
      ></RecordSport>
    </div>
    <fw-panel-info v-else type="basic" class="text-center my-5 text-gray-500">
      {{ $t('nomodalities') }}
    </fw-panel-info>
  </div>
</template>
<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordSport from '@/components/records/RecordSport'
export default {
  components: {
    LoadingPlaceholder,
    RecordSport
  },
  data() {
    return {
      loading: false,
      sports: [],
      modalities: {}
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        let response = await this.api.getSports()
        this.sports = response.data
        if (Array.isArray(response.modalities) && response.modalities.length > 0) {
          response.modalities.forEach(modality => {
            this.modalities[modality.key] = modality
          })
        }
      } finally {
        this.loading = false
      }
    },
    refresh() {
      this.load()
    }
  }
}
</script>

<i18n>
    {
      "pt": {
        "nomodalities": "Sem modalidades a mostrar"
      },
      "en": {
        "nomodalities": "No modalities to show"
      }
    }
    </i18n>
