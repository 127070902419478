<template>
  <fw-panel :title="tmpSport.key ? 'Editar desporto' : 'Criar desporto'">
    <div>
      <fw-label>Nome da edição</fw-label>
      <b-input v-if="editable" v-model="tmpSport.name"></b-input>
      <div v-else>
        {{ tmpSport.name }}
      </div>
      <fw-tip v-if="$v.tmpSport.name.$error" error> Insira um nome para o desporto</fw-tip>
    </div>
    <div>
      <fw-label>Datas de início e fim da edição</fw-label>
      <div v-if="editable" class="flex gap-5">
        <b-datepicker
          v-model="tmpSport.start_at"
          :min-date="minDate"
          :years-range="[0, 50]"
          locale="pt-PT"
          :placeholder="'Data de início'"
        >
        </b-datepicker>
        <b-datepicker
          v-model="tmpSport.end_at"
          :min-date="tmpSport.start_at || minDate"
          :years-range="[0, 50]"
          locale="pt-PT"
          :placeholder="'Data de fim'"
        >
        </b-datepicker>
      </div>
      <div v-else class="flex gap-5">{{ tmpSport.start_at | formatDate }} - {{ tmpSport.end_at | formatDate }}</div>
      <fw-tip v-if="$v.tmpSport.start_at.$error" error> Insira data de início </fw-tip>
      <fw-tip v-if="$v.tmpSport.end_at.$error" error> Insira data de fim </fw-tip>
    </div>

    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="closeModal()">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button
        v-if="tmpSport.key && editable"
        :type="'xlight'"
        :disable="saving"
        :saving="saving"
        class="w-28"
        @click.native="publish()"
      >
        {{ 'Publicar' }}
      </fw-button>
      <fw-button
        v-if="tmpSport.key && editable"
        :type="'primary'"
        :disable="saving"
        :saving="saving"
        class="w-28"
        @click.native="sendData()"
      >
        {{ 'Guardar' }}
      </fw-button>
      <fw-button
        v-else-if="editable"
        :type="'primary'"
        :disable="saving"
        :saving="saving"
        class="w-28"
        @click.native="sendData()"
      >
        {{ 'Criar' }}
      </fw-button>
    </div>
  </fw-panel>
</template>
<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { required } from 'vuelidate/lib/validators'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
export default {
  name: 'ModalCreateSport',
  components: {},
  props: {
    sport: {
      type: Object
    }
  },
  data() {
    return {
      saving: false,
      minDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      tmpSport: {
        name: '',
        start_at: null,
        end_at: null,
        status: 'draft'
      }
    }
  },
  computed: {
    editable() {
      return this.tmpSport.status != 'published'
    },
    api() {
      return this.$store.state.api.base
    }
  },
  mounted() {
    if (this.edition != null) {
      console.log(this.edition)
      let data = JSON.parse(JSON.stringify(this.sport))
      data.start_at = new Date(data.start_at)
      data.end_at = new Date(data.end_at)
      this.tmpSport = data
    }
  },
  validations: {
    tmpSport: {
      name: { required },
      start_at: { required },
      end_at: { required }
    }
  },
  methods: {
    async publish() {
      this.sendData(true)
    },
    async sendData(publish) {
      this.$v.$touch()
      if (this.$v.tmpSport.$error) return
      this.saving = true
      utils.tryAndCatch(
        this,
        async () => {
          let data = JSON.parse(JSON.stringify(this.tmpSport))
          if (data.start_at) {
            data.start_at = this.tmpSport.start_at.toISOString().split('T')[0]
          }
          if (data.end_at) {
            data.end_at = this.tmpSport.end_at.toISOString().split('T')[0]
          }
          if (publish) {
            data['submit'] = true
          }
          const response = this.tmpSport.key
            ? await this.api.updateSport(this.tmpSport.key, data)
            : await this.api.createSport(data)
          this.$emit('saved', response)
        },
        () => {
          this.saving = false
        }
      )
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>
